import eventStoreModule from '@/store/events/event'
import { computed, getCurrentInstance, ref } from '@vue/composition-api'

export default function useEventView(eventId) {
  const vm = getCurrentInstance().proxy

  const id = ref(eventId)
  const errorMessages = ref({})

  const MODULE_CONTAINER = 'events'
  const STORE_MODULE = computed(() => `${MODULE_CONTAINER}/${id.value}`)

  const event = computed({
    get: () => vm.$store.getters[`${STORE_MODULE.value}/data`],
  })
  const loading = computed({
    get: () => vm.$store.getters[`${STORE_MODULE.value}/loading`],
  })
  const updating = computed({
    get: () => vm.$store.getters[`${STORE_MODULE.value}/updating`],
  })

  // fetch data
  const fetchData = () => vm.$store.dispatch(`${STORE_MODULE.value}/fetchData`)

  // update data
  const updateData = formData =>
    vm.$store
      .dispatch(`${STORE_MODULE.value}/updateData`, formData)
      .then(res => res?.status === 200 && vm.$router.push({ name: 'event-view', params: { id: eventId } }))
      .catch(error => {
        // console.log(error)
        if (error.status === 'fail') {
          errorMessages.value = error.data.errors
        }
      })

  // onInit
  // eslint-disable-next-line no-unused-vars
  const init = (_id = null) => {
    // if (_id) id.value = _id

    if (!vm.$store.hasModule([MODULE_CONTAINER, id.value])) {
      vm.$store.registerModule([MODULE_CONTAINER, id.value], eventStoreModule(id.value))
    }

    vm.$store.dispatch(`${STORE_MODULE.value}/init`, id.value)

    fetchData()
  }

  // onDestroy
  const destroy = () => {
    // TODO: SHOULD DESTROY MODULES?
    // if (vm.$store.hasModule([MODULE_CONTAINER, id.value])) {
    //   vm.$store.unregisterModule([MODULE_CONTAINER, id.value])
    // }
  }

  // watch([], () => {
  // loading.value = true
  // fetchData()
  // })

  return {
    event,
    loading,
    updating,

    errorMessages,

    init,
    destroy,

    fetchData,
    updateData,
  }
}
