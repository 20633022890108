<template>
  <event-form
    v-if="event"
    action="update"
    :loading="loading"
    :updating="updating"
    :data="event"
    :error-messages="errorMessages"
    :handle-submit="handleFormSubmit"
    :handle-discard="handleDiscard"
  />
</template>

<script>
// eslint-disable-next-line object-curly-newline, no-unused-vars
import { onUnmounted, ref, computed, provide, getCurrentInstance } from '@vue/composition-api'

import { useRouter } from '@core/utils'
import useEventView from './useEventView'

import EventForm from './event-form/EventForm.vue'

export default {
  components: {
    EventForm,
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { route } = useRouter()

    const {
      event,
      loading,
      updating,

      errorMessages,

      init,
      destroy,

      updateData,
    } = useEventView(route.value.params.id)

    // Init
    init()

    // Destroy
    onUnmounted(() => {
      destroy()
    })

    // const eventDataLocal = computed(() => event.value)

    const handleFormSubmit = formData => updateData(formData)
    const handleDiscard = () => vm.$router.replace({ name: 'event-view', params: { id: route.value.params.id } })

    return {
      event,
      loading,
      updating,
      updateData,

      errorMessages,

      // eventDataLocal,

      handleFormSubmit,
      handleDiscard,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
